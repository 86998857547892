export const titleCase = (str) => {
  if (typeof str === 'undefined') return '';

  if (str === null) return '';

  if (str === '') return '';

  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
