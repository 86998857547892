import Link from 'components/common/CustomLink';
import Image from 'next/image';
import NProgress from 'nprogress';
import Prev from 'public/images/pagination-left.svg';
import Next from 'public/images/pagination-right.svg';
import React from 'react';

export default function PageButton({
  url,
  page,
  curPage,
  className,
  shallow,
  type = 'page',
}) {
  const urlParts = url.split('?');
  const urlParams = new URLSearchParams(urlParts[1] ?? '');
  if (page === 1) {
    urlParams.delete('page');
  } else {
    urlParams.set('page', page);
  }
  const hasParams = urlParams.size ?? Array.from(urlParams.keys()).length;
  const href = hasParams ? `${urlParts[0]}?${urlParams}` : urlParts[0];

  if (type === 'prev') {
    return (
      <Link
        href={href}
        onClick={() => NProgress.start()}
        className={className}
        shallow={shallow}
      >
        <Image src={Prev} width={36} height={36} alt="Previous" />
      </Link>
    );
  }

  if (type === 'next') {
    return (
      <Link
        href={href}
        onClick={() => NProgress.start()}
        className={className}
        shallow={shallow}
      >
        <Image src={Next} width={36} height={36} alt="Next" />
      </Link>
    );
  }

  if (curPage === page) {
    return (
      <div
        aria-current="page"
        className={`${className} primary-purple-10 text-slate-400 border p-2 rounded-[4px] h-12 w-12 text-base hover:shadow-xl flex justify-center items-center cursor-pointer`}
      >
        {page}
      </div>
    );
  }

  return (
    <Link
      href={href}
      onClick={() => NProgress.start()}
      className={className}
      shallow={shallow}
    >
      <div
        aria-current={false}
        className="bg-transparent p-2 rounded-[4px] h-12 w-12 text-base text-indigo-700 hover:shadow-xl hover:bg-white flex justify-center items-center"
      >
        {page}
      </div>
    </Link>
  );
}
