import Image from 'next/image';
import React from 'react';
import imageLoader from 'utils/imageLoader';

export default function CdnImage(properties = {}) {
  const props = { ...properties };
  if (!props.loader) {
    props.loader = imageLoader;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Image {...props} />
  );
}
