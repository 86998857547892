import Image from 'next/image';
import IconUnverified from 'public/images/verified/tag-unverified-blk.svg';
import IconVerified from 'public/images/verified/tag-verified-blk.svg';
import React from 'react';

export default function VerificationFlag({ property, top }) {
  if (!property)
    return (
      <div className="bg-slate-300 animate-pulse rounded-lg mx-4">&nbsp;</div>
    );

  const icon = property.verified_flag ? IconVerified : IconUnverified;
  const text = property.verified_flag ? 'verified' : 'unverified';

  return (
    <div
      className={`${top} relative align-middle self-start place-content-center`}
    >
      <Image
        src={icon}
        height={38}
        width={120}
        className="w-[80px] ml-2 mt-2 md:w-[90px] lg:w-[100px]"
        alt={`This property is verified ${text}`}
      />
    </div>
  );
}
