import Helmet from 'components/HeadTags/Helmet';
import CdnImage from 'components/common/CdnImage';
import Link from 'components/common/CustomLink';
import Paginator from 'components/common/Paginator/Paginator';
import VerificationFlag from 'components/common/VerificationFlag/VerificationFlag';
// import RoundedIcon from 'components/containers/Icons/RoundedIcon';
import { useAuth } from 'hooks/auth/useAuth';
import { getIsSsrMobile, useIsMobile } from 'hooks/isMobile';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import NProgress from 'nprogress';
// import CompanyIcon from 'public/images/company.svg';
import GlobeIcon from 'public/images/icons/globe-black.svg';
import EmptyImage from 'public/images/property/empty.jpeg';
import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import HeaderFooterTemplate from 'templates/HeaderFooterTemplate';
import calculateAds from 'utils/calculateAds';
import { titleCase } from 'utils/formatting';
import { getPaginatorBaseUrl } from 'utils/getPaginatorBaseUrl';
import growthbookFetcher from 'utils/growthbookFetcher';
import imgLoader from 'utils/imageLoader';
import { removeURLParameter } from 'utils/removeURLParameter';

const AdSlot = dynamic(() => import('components/ads/AdSlot'), {
  ssr: false,
  loading: () => <div className="w-[300px] h-[250px]" />,
});

const API_PAGE_SIZE = 36;

const fetcher = async (companyId, page) => {
  let buildingsUrl = `${process.env.REACT_APP_API_BASE}/endpoint/company/${companyId}/buildings`;

  if (page > 1) {
    buildingsUrl = `${buildingsUrl}?page=${page}`;
  }
  return fetch(buildingsUrl).then((response) => response.json());
};

function BuildingCardsLoader({ adsCount, cardsCount }) {
  let key = 1;
  return (
    <>
      {[...Array((cardsCount ?? 0) + (adsCount ?? 0)).keys()].map(() => {
        key += 1;
        return (
          <div key={`b-preview-${key}`}>
            <div className="w-[350px] sm:w-[300px] h-[300px] relative overflow-hidden bg-white rounded-lg shadow-lg shadow-gray-100 flex flex-col justify-start cursor-pointer">
              <div className="relative aspect-[5/3.5] bg-gray-200 animate-pulse">
                &nbsp;
              </div>
              <div className="p-4">
                <div className="text-sm w-1/2 rounded-lg bg-gray-200 animate-pulse">
                  &nbsp;
                  <br />
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

function imageLoader({ src, quality }) {
  return imgLoader({ src, quality, width: 384 });
}

function BuildingCard({ building, company, priority }) {
  const preview = building?.media.length
    ? building.media[0].media_url
    : EmptyImage;
  const [imgSrc, setImgSrc] = useState(preview);
  const url = `/company/${company.id}/${company.slug}/${building?.id}/${building?.slug}`;

  const fullAddress = `${building?.address_1} ${building?.address_city}, ${building?.address_state}`;

  return (
    <Link href={url} aria-label={fullAddress}>
      <div className="w-[350px] sm:w-[300px] h-[300px] relative overflow-hidden bg-white rounded-lg shadow-lg shadow-gray-100 flex flex-col justify-start cursor-pointer">
        <div className="relative aspect-[5/3.5] overflow-hidden">
          <CdnImage
            src={imgSrc}
            fill
            className="relative h-[250px] isolate rounded-tr-lg rounded-tl-lg overflow-hidden"
            alt={`Main picture of ${building?.property_name ?? fullAddress}`}
            onError={() => setImgSrc(EmptyImage)}
            loader={imageLoader}
            sizes="352px"
            priority={priority}
          />
        </div>
        <div className="p-4 ">
          <div className="capitalize text-sm">{building?.address_1}</div>
          <div className="capitalize text-xs">
            {building?.address_city}, {building?.address_state}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default function CompanyPage({
  companyId,
  company,
  initialBuildings,
  initialAds,
  adFrequency,
}) {
  const isMobile = useIsMobile();
  const query = useSearchParams();
  const router = useRouter();
  const path = usePathname();
  const paginatorBaseUrl = useMemo(
    () => getPaginatorBaseUrl(path, query, ['companyId', 'companySlug']),
    [path, query],
  );
  const auth = useAuth();
  const [ads, setAds] = useState(initialAds);
  const [nextPageAds, setNextPageAds] = useState(initialAds);
  const [buildingFallbackData, setBuildingFallbackData] =
    useState(initialBuildings);
  const adsEnabled =
    !auth?.user?.edge_subscribed && process.env.NEXT_PUBLIC_NO_ADS !== 'true';
  const page = query.get('page') ?? 1;

  const {
    data: building,
    isValidating,
    error,
  } = useSWR(companyId ? [companyId, page] : null, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    fallbackData: buildingFallbackData,
    revalidateOnMount: false,
  });

  useEffect(() => {
    if (isValidating) {
      setAds(nextPageAds);
    } else {
      const newAds = calculateAds({ adFrequency, dwellsyAdsFrequency: null });
      setNextPageAds(newAds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating]);

  useEffect(() => {
    setBuildingFallbackData(building);
  }, [building]);

  useEffect(() => {
    if (isValidating) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [building, isValidating]);

  if (error) return <div>Error</div>;

  // navigate to the first page number is too big
  if (!isValidating && building?.buildings?.length === 0 && page > 1) {
    const url = removeURLParameter(path, 'page');
    router.push(url);
  }

  const totalBuildings =
    initialBuildings?.total_count ?? building.total_count ?? API_PAGE_SIZE * 2;
  const buildingsLeft = totalBuildings - API_PAGE_SIZE * (page - 1);
  const loadingCardsNumber =
    // eslint-disable-next-line no-nested-ternary
    buildingsLeft > API_PAGE_SIZE
      ? API_PAGE_SIZE
      : buildingsLeft > 0
      ? buildingsLeft
      : 8;

  return (
    <HeaderFooterTemplate>
      <Helmet
        title={`Places for rent in ${company?.company_name_displayed}`}
        description={`Check out all of the properties managed by ${company?.company_name_displayed} that currently have available listings`}
      />
      <div className="md:px-12 lg:mx-auto">
        <div className="mb-2">
          <div className="py-4 px-2 sm:px-0">
            <div className="w-full text-center flex flex-col justify-center items-center">
              {/* <span className=" -ml-14 hidden md:block">
                <RoundedIcon
                  icon={CompanyIcon}
                  alt="Company icon"
                  className="bg-[#f2f1ff] "
                />
              </span> */}
              {company?.logo ? (
                <div className="relative h-20 w-full max-w-[250px] mb-4">
                  <Image
                    id="company_logo"
                    src={company?.logo}
                    alt="company logo"
                    fill
                    className="object-contain"
                  />
                </div>
              ) : null}
              <div className="md:flex justify-center items-center">
                <h1 className="text-xl md:text-2xl lg:text-4xl md:pl-9 md:mr-2 font-semibold md: w-auto text-center">
                  {company?.company_name_displayed}
                </h1>
                <div className="flex md:block items-center w-auto justify-center h-8">
                  <VerificationFlag top="top-[-2px]" property={company} />
                </div>
              </div>
              <div>
                <span className="leading-4 text-sm">
                  {titleCase(company?.address_1)}{' '}
                </span>
                {company.address_2 ? (
                  <span className="leading-4 text-base">
                    {titleCase(company?.address_2)}{' '}
                  </span>
                ) : null}
                {company.address_zip ? (
                  <span className="leading-4 text-sm">
                    {titleCase(company?.address_city)},{' '}
                    {company?.address_state?.toUpperCase()}{' '}
                    {company?.address_zip}
                  </span>
                ) : null}
              </div>
              {company?.website ? (
                <div className="flex mr-1">
                  <Image
                    src={GlobeIcon}
                    alt="Globe icon"
                    width="14"
                    height="14"
                  />

                  <p className="">
                    <Link
                      href={company?.website}
                      target="_blank"
                      rel={company?.slug === 'udr' ? '' : 'noopener noreferrer'}
                      className="text-primary-purple text-sm ml-1"
                    >
                      Website
                    </Link>
                  </p>
                </div>
              ) : null}
              {company.description ? (
                <p className="my-6 font-medium max-w-2xl text-left text-sm">
                  {company?.description}
                </p>
              ) : null}
            </div>
          </div>
          <strong className=" block text-lg text-center w-full font-semibold my-5">
            Properties listed by {company?.company_name_displayed}
          </strong>
          <div className="grid gap-x-6 gap-y-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 sm:w-fit mx-auto min-h-[250px] justify-items-center">
            {isValidating ? (
              <BuildingCardsLoader
                cardsCount={loadingCardsNumber}
                adsCount={
                  adsEnabled
                    ? (nextPageAds?.google?.length ?? 0) +
                      (nextPageAds?.dwellsy?.length ?? 0)
                    : 0
                }
              />
            ) : (
              building?.buildings.map((item, i) => (
                <React.Fragment key={item?.id}>
                  {adsEnabled && ads?.google?.includes(i) && (
                    // <div className="flex justify-center items-center mb-2 lg:mb-6 h-[320px] lg:h-[100px]">
                    <div
                      className="min-w-[300px] max-w-[350px] w-full sm:w-[300px] relative overflow-hidden bg-white rounded-lg shadow-lg shadow-gray-100 flex flex-col justify-end"
                      style={{ height: '300px' }}
                    >
                      <div className="flex flex-1 justify-center overflow-hidden">
                        <AdSlot
                          id="company-results-panel_card_1"
                          allocation={{
                            desktopXL: [[300, 250]],
                            desktop: [[300, 250]],
                            tablet: [[300, 250]],
                            mobile: [[300, 250]],
                          }}
                          randomId={i}
                        />
                      </div>
                    </div>
                  )}
                  <BuildingCard
                    building={item}
                    company={company}
                    priority={isMobile ? i < 2 : i < 8}
                  />
                </React.Fragment>
              ))
            )}
          </div>
          <Paginator
            total={building?.total_count}
            baseRoute={paginatorBaseUrl}
            limitPerPage={36}
            currentPage={parseInt(page, 10) ?? 1}
          />
        </div>
      </div>
    </HeaderFooterTemplate>
  );
}

export async function getServerSideProps(context) {
  const { params, query } = context;
  const { companyId, companySlug } = params;
  const page = query.page ?? 1;

  const data = await fetcher(companyId, page);

  if (data?.error === 'Company Not Found' || !data.company) {
    return {
      notFound: true,
    };
  }

  const redirUrl = `/company/${data.company.id}/${data.company.slug}`;
  if (data.company.slug !== companySlug) {
    return {
      redirect: {
        destination: redirUrl,
        permanent: false,
      },
    };
  }

  if (data.buildings.length === 0 && page > 1) {
    return {
      redirect: {
        destination: redirUrl,
        permanent: false,
      },
    };
  }

  if (data.company.parent_company_id) {
    const redirectUrl = `/company/${data.company.parent_company_id}/${data.company.slug}`;
    return {
      redirect: {
        destination: redirectUrl,
        permanent: false,
      },
    };
  }

  let adFrequency = 0;
  let initialAds = {};

  if (process.env.NEXT_PUBLIC_NO_ADS !== 'true') {
    adFrequency = await growthbookFetcher({
      feature: 'adfrequencyincompanypageresults',
      fallback: 4,
    });

    initialAds = calculateAds({ adFrequency, dwellsyAdsFrequency: null });
  }
  return {
    props: {
      companyId,
      company: data.company,
      initialBuildings: data,
      initialAds,
      adFrequency,
      isSsrMobile: getIsSsrMobile(context),
    },
  };
}
