export const removeURLParameter = (url, parameter) => {
  const urlParts = url.split('?');
  if (urlParts.length >= 2) {
    const newQuery = new URLSearchParams(urlParts[1]);
    newQuery.delete(parameter);

    const newQueryString = newQuery.toString();
    return newQueryString.length
      ? `${urlParts[0]}?${newQueryString}`
      : urlParts[0];
  }
  return url;
};

export default removeURLParameter;
