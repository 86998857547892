export default function imageLoader({ src, width, quality }) {
  if (!src) {
    return null;
  }

  const fileName =
    process.env.CDN_URL &&
    src.startsWith('https://s3-us-west-2.amazonaws.com/v2media.dwellsy.com/')
      ? src.replace(
          'https://s3-us-west-2.amazonaws.com/v2media.dwellsy.com/',
          '',
        )
      : null;

  if (src.startsWith('/images/')) {
    return process.env.CDN_URL ? `${process.env.CDN_URL}${src}` : `${src}`;
  }

  return fileName
    ? `${process.env.CDN_URL}/_next_/image/w${width}/q${
        quality || 75
      }/${fileName}`
    : `/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${
        quality || 75
      }`;
}
