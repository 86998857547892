export const getPaginatorBaseUrl = (path, queryString, removeParts = []) => {
  const paginatorSearchParam = new URLSearchParams(queryString);
  removeParts.forEach((param) => {
    paginatorSearchParam.delete(param);
  });
  const query = paginatorSearchParam.toString();

  return query.length ? `${path}?${query}` : path;
};

export default getPaginatorBaseUrl;
