function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function calculateAds({ adFrequency, promoAds }) {
  const ads = {};

  if (adFrequency?.length && promoAds?.length) {
    // sort PromoAds
    const sortedAds = promoAds
      .map((a) => a)
      .sort((a, b) => {
        const aPos =
          a?.position ??
          randomIntFromInterval(promoAds.length + 1, promoAds.length * 2);
        const bPos =
          b?.position ??
          randomIntFromInterval(promoAds.length + 1, promoAds.length * 2);

        return aPos - bPos;
      });

    let adIdx = 0;
    adFrequency.forEach((f) => {
      const pos = randomIntFromInterval(f.min, f.max);
      if (adIdx >= sortedAds.length) {
        adIdx = 0;
      }
      ads[pos] = sortedAds[adIdx];
      adIdx += 1;
    });
  }

  return ads;
}

export default calculateAds;
