import { usePathname } from 'next/navigation';
import React from 'react';

import PageButton from './PaginatorButton';

const PAGINATOR_MOBILE_BUTTONS_COUNT = 5;
const PAGINATOR_BUTTONS_COUNT = 9;

export default function Paginator({
  total = 0,
  currentPage = 1,
  limitPerPage = 36,
  baseRoute = null,
  shallow = true,
}) {
  const curPage = currentPage || 1;
  const path = usePathname();
  const url = baseRoute || path;
  const pageCount = Math.ceil(total / limitPerPage);
  const min = Math.max(
    1,
    Math.min(
      curPage - Math.floor(PAGINATOR_BUTTONS_COUNT / 2),
      pageCount + 1 - PAGINATOR_BUTTONS_COUNT,
    ),
  );
  const max = Math.min(
    pageCount,
    Math.max(
      curPage + Math.floor(PAGINATOR_BUTTONS_COUNT / 2),
      PAGINATOR_BUTTONS_COUNT,
    ),
  );
  const mobileMin = Math.max(
    1,
    Math.min(
      curPage - Math.floor(PAGINATOR_MOBILE_BUTTONS_COUNT / 2),
      pageCount + 1 - PAGINATOR_MOBILE_BUTTONS_COUNT,
    ),
  );
  const mobileMax = Math.min(
    pageCount,
    Math.max(
      curPage + Math.floor(PAGINATOR_MOBILE_BUTTONS_COUNT / 2),
      PAGINATOR_MOBILE_BUTTONS_COUNT,
    ),
  );
  const pages = Array.from(new Array(max - min + 1), (x, i) => i + min);
  const isNextPage = pageCount > curPage;
  const isPrevPage = curPage - 1 > 0;

  return (
    <nav aria-label="pagination">
      <div className="flex justify-center items-center py-4">
        <div className="flex justify-between items-center">
          {isPrevPage ? (
            <PageButton
              page={curPage - 1}
              curPage={curPage}
              url={url}
              className="justify-center items-center h-11 flex w-11"
              shallow={shallow}
              type="prev"
            />
          ) : null}

          <div className="flex items-center h-12">
            {pages.map((page) => (
              <PageButton
                key={`page-${page}`}
                page={page}
                curPage={curPage}
                url={url}
                className={
                  page < mobileMin || page > mobileMax ? 'hidden sm:flex' : ''
                }
                shallow={shallow}
              />
            ))}
          </div>

          {isNextPage ? (
            <PageButton
              page={curPage + 1}
              curPage={curPage}
              url={url}
              className="justify-center items-center h-11 flex w-11"
              shallow={shallow}
              type="next"
            />
          ) : null}
        </div>
      </div>
    </nav>
  );
}
